import "./companies.css"
const Companies = () =>{
    return(
        <div>
       <section className="c-wrapper">
        <div className="padding innerwidth flexCenter c-container">
            <img src="./images/prologis.png"  alt=""/>
            <img src="./images/tower.png"  alt=""/>
            <img src="./images/equinix.png"  alt=""/>
            <img src="./images/realty.png"  alt=""/>
        </div>
       </section>

        </div>
    )
}

export default Companies